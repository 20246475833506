import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/gHaZ79sxedA">
    <SEO title="Facing God-allowed Difficulties - Living the Dream" />
  </Layout>
)

export default SermonPost
